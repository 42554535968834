/*! Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css */
@media print { *, *:before, *:after { background: transparent !important; color: #000 !important; box-shadow: none !important; text-shadow: none !important; }
  a, a:visited { text-decoration: underline; }
  a[href]:after { content: " (" attr(href) ")"; }
  abbr[title]:after { content: " (" attr(title) ")"; }
  a[href^="#"]:after, a[href^="javascript:"]:after { content: ""; }
  pre, blockquote { border: 1px solid #999; page-break-inside: avoid; }
  thead { display: table-header-group; }
  tr, img { page-break-inside: avoid; }
  img { max-width: 100% !important; }
  p, h2, h3 { orphans: 3; widows: 3; }
  h2, h3 { page-break-after: avoid; }
  .navbar { display: none; }
  .btn > .caret, .dropup > .btn > .caret { border-top-color: #000 !important; }
  .label { border: 1px solid #000; }
  .table { border-collapse: collapse !important; }
  .table td, .table th { background-color: #fff !important; }
  .table-bordered th, .table-bordered td { border: 1px solid #ddd !important; } }
a:link, a:visited { color: black; background: transparent; font-weight: bold; text-decoration: none; }

body { background: white; font-size: 12pt; line-height: 1.4; }

div#content, .panel, h3.dropdown, .offer { padding-top: 1em; border-top: 1px solid #86B13C; }

.cred_prev_panel { padding-bottom: 1em; border-bottom: 1px solid #86B13C; }

h1, h2, h3, h4, h5, h6 { margin: 0; }

h1, h6, li, legend, dt { font-size: 100%; }

h2 { font-size: 250%; }

h3 { font-size: 160%; }

h4, .sidebar h6 { font-size: 140%; }

h5, .cred_name, .acc_no, .amount { font-size: 120%; font-weight: bold; }

.hgroup h2 a:link:after { content: " > "; }

.small_print { font-size: 80%; }

.wrapper, #content { width: auto; margin: 0; padding: 0; border: 0; float: none !important; color: black; background: transparent none; }

#client_ref, .panel img, .hidebyjs img, .offer img { float: right; }

#content a:link, #content a:visited { color: #520; text-decoration: underline; }

#content a:link:after, #content a:visited:after { /*content: " (" attr(href) ") ";*/ font-size: 90%; }

#content a[href^="/"]:after { /*content: " (http://www-payplanplus-co-uk.localhost" attr(href) ") ";*/ }

#navigation, ul.nav, .pagination, #pagination, #bookmark_tip, #bookmark_remove_tip, .expand_link, #quicklinks, #sectionlinks, .col.six a.action, .col.six a.action.main, #progressbar { display: none !important; }

table { width: 99%; border: 1px 1px 0 0; border-style: solid; border-color: black; border-collapse: collapse; margin: 10pt 0; }

table th, table td { border: 0 0 1px 1px; border-style: solid; border-color: black; padding: 5pt; }

form ol { list-style: none; margin: 0; padding: 0; }

#categories ul, .head ul { margin: inherit 0; }

#categories ul li, .cred_name, .acc_no, .amount { display: inline; margin: 0 1em; }

.question, .answer, .cred_prev_panel, .hidebyjs, blockquote cite { display: block !important; }
